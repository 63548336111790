<template>
  <div style="background-color: white; background-size: 100% 100%;">
    <div :class="`d-flex flex-column ${isMobile ? 'my-5' : 'my-10'}`" v-if="eventList">
      <b-tabs :fill="isMobile" :class="isMobile ? 'mx-1' : 'mx-10'" v-model="tabIndex">
        <b-tab :title="$t(tab == 1 ? 'event' : 'drink')" v-for="tab in 2" :key="tab">
          <template #title>
            <span :class="`font-15 font-bold ${tab - 1 == tabIndex ? tab == 1 ? 'color-purple' : 'color-drink' : ''} ${isMobile ? '' : 'px-20 py-2'}`">{{ $t(tab == 1 ? 'event' : 'drink') }}</span>
          </template>
          <div class="d-flex flex-column" v-for="i in 2" :key="i">
            <div :class="`d-flex vertical-center ${isMobile ? 'mx-10 mt-5' : 'mx-20 mt-10'}`">
              <span class="font-15">{{ `${i == 1 ? $t('live_event') : $t('past_event')}` }}</span>
              <div :class="`${isMobile ? 'w-50' : 'col-sm'} ml-5 separator separator-solid`" />
            </div>
            <div :class="`row ${isMobile ? 'm-2' : 'mx-10 mt-2'}`" v-if="(i == 1 ? liveList : pastList).length > 0">
              <div class="my-2 col-xl-3 col-lg-4 col-md-6 col-sm-12" v-for="(item, index) in (i == 1 ? liveList : pastList)" :key="(item, index)">
                <v-card class="mx-auto box-white d-flex flex-column">
                  <v-img class="rounded" height="200px" :src="item.photoUrl" />
                  <span class="mt-5 ellipsis font-bold font-18" :title="item.name">{{ item.name }}</span>
                  <div class="mt-2 vertical-center">
                    <i class="fa fa-calendar-alt font-12 color-blue"></i>
                    <span class="ml-2 font-12 color-blue">{{ getDateStringFromTimestamp(item.startAt) }} - {{ getDateStringFromTimestamp(item.endAt) }}</span>
                  </div>
                  <div class="mt-2 vertical-center">
                    <i :class="`flaticon2-pin-1 font-12 ${tab == 1 ? 'color-purple' : 'color-drink'}`"></i>
                    <span :class="`ml-2 font-12 ${tab == 1 ? 'color-purple' : 'color-drink'}`">{{ getCountryCityFromAddress(item.address) }}</span>
                    <span class="ml-2 font-12 color-red" v-if="item.hideAddress">{{ $t('private') }}</span>
                  </div>
                  <div class="my-5 mx-auto w-75 div-divider-h" />
                  <div class="my-1 d-flex justify-content-between" v-if="tab == 1">
                    <span class="font-15 color-purple">{{ `${getDecimalFormat(item.soldQuantity)} / ${getDecimalFormat(item.totalQuantity)}` }}</span>
                    <span class="font-15 font-bold color-purple">{{ getPriceFormat(item.netSales) }}</span>
                  </div>
                  <div class="my-1 d-flex justify-content-between" v-else>
                    <span class="font-15 color-drink">{{ `${getDecimalFormat(item.drinkSoldQuantity)} / ${getDecimalFormat(item.drinkTotalQuantity)}` }}</span>
                    <span class="font-15 font-bold color-drink">{{ getPriceFormat(item.drinkPayoutAmount) }}</span>
                  </div>
                  <div class="my-5 mx-auto w-75 div-divider-h" />
                  <div class="my-1 d-flex justify-content-between" v-if="tab == 1">
                    <v-btn icon @click="$router.push(`/edit_event/${item.eventId}`)" v-if="i == 1">
                      <i class="fa fa-edit color-purple font-20"></i>
                    </v-btn>
                    <v-btn icon @click="showEventModal(item)">
                      <i class="fa fa-clone color-purple font-20"></i>
                    </v-btn>
                    <v-btn icon @click="deleteEventTask(item)" v-if="i == 1">
                      <i class="ml-1 flaticon-delete-1 color-red font-20"></i>
                    </v-btn>
                    <v-btn class="ml-auto button-small" @click="$router.push(`/dashboard/${item.eventId}`)" :disabled="!item.ticketCount || item.ticketCount === 0">
                      <span :class="`${isMobile? 'mx-2' : 'mx-5'}`">{{ $t('dashboard') }}</span>
                    </v-btn>
                  </div>
                  <div class="my-1 d-flex justify-content-between" v-else>
                    <v-btn icon @click="$router.push(`/edit_drink/${item.eventId}`)">
                      <i class="ml-1 fa fa-plus color-drink font-20" v-if="!item.drinkTotalQuantity"></i>
                      <i class="ml-1 fa fa-edit color-drink font-20" v-else></i>
                    </v-btn>
                    <v-btn icon @click="showEventSelectingPopup(item)" v-if="canImportDrinks(item)">
                      <i class="ml-1 fas fa-file-import color-drink font-20"></i>
                    </v-btn>
                    <v-btn class="ml-auto button-drink-small" @click="$router.push(`/drink_dashboard/${item.eventId}`)" :disabled="!item.drinkTotalQuantity || item.drinkTotalQuantity === 0">
                      <span :class="`${isMobile? 'mx-2' : 'mx-5'}`">{{ $t('dashboard') }}</span>
                    </v-btn>
                  </div>
                </v-card>
              </div>
            </div>
            <div class="my-30 center" v-else>
              <p>{{ $t('no_data_found') }}</p>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <div class="my-30 py-40 center" v-else>
      <b-spinner type="grow"></b-spinner>
      <p class="mt-5">{{ $t('alert_please_wait') }}</p>
    </div>

    <!-- Modal for editing event -->
    <b-modal
      v-model="isShowEditEvent" 
      centered
    >
      <template #modal-header>
        <h5 class="mb-0 text-white">{{ $t('enter_new_event_info') }}</h5>
      </template>

      <template #default>
        <div :class="isMobile ? 'm-2' : 'w-100 d-flex flex-column'">
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('event_name') }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="eventName" maxlength="100" oninput="this.value = this.value.replace(/[^0-9a-zA-Z \S]/g,'');" />
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('start_date') }}</span>
            <div :class="`d-flex ${isMobile ? 'mt-1' : 'w-75'}`">
              <DatePicker
                :class="`${isMobile ? 'w-50' : ''}`"
                v-model="startDate"
                format="DD.MM.YYYY"
                type="date"
                :placeholder="$t('start_date')"
              />
              <DatePicker
                :class="`${isMobile ? 'w-50' : 'ml-1'}`"
                v-model="startTime"
                format="HH:mm"
                type="time"
              />
            </div>
          </div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('end_date') }}</span>
            <div :class="`d-flex ${isMobile ? 'mt-1' : 'w-75'}`">
              <DatePicker
                :class="`${isMobile ? 'w-50' : ''}`"
                v-model="endDate"
                format="DD.MM.YYYY"
                type="date"
                :placeholder="$t('end_date')"
              />
              <DatePicker
                :class="`${isMobile ? 'w-50' : 'ml-1'}`"
                v-model="endTime"
                format="HH:mm"
                type="time"
              />
            </div>
          </div>
        </div>
      </template>

      <template #modal-footer>
        <div class="w-100">
          <v-btn
            class="float-right button-small m-auto"
            @click="duplicateEventInfoTask"
          >
          {{ $t('ok') }}
          </v-btn>
        </div>
      </template>
    </b-modal>
    <!-- End Event Editing Modal -->

    <!-- Start Event Selecting Modal -->
    <b-modal
      v-model="showEventSelectingModal" 
      centered
      scrollable
    >
      <template #modal-header>
        <h5 class="mb-0 text-white">{{ $t('select_event') }}</h5>
      </template>

      <template #default>
        <div class="mt-2 event-box px-3 py-2" v-for="(item, index) in getEventListForDrinkClone()" :key="index"  @click="handleSelectEvent(item)">
          <div class="mt-2 vertical-center">
            <span class="font-12 color-blue">{{ getDateStringFromTimestamp(item.startAt) }} - {{ getDateStringFromTimestamp(item.endAt) }}</span>
          </div>
          <span class="ellipsis font-bold font-18" :title="item.name"> {{ item.name }}</span>
        </div>
        <div v-if="!getEventListForDrinkClone().length" class="text-center">
          {{ $t('no_data_found') }}
        </div>
      </template>

      <template #modal-footer>
        <div class="w-100" />
      </template>
    </b-modal>
    <!-- End Event Selecting Modal -->

    <!-- Start Employee Entering Modal -->
    <b-modal
      v-model="showEmployeeEnteringModal" 
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header>
        <h5 class="mb-0 text-white">{{ $t('add_employee') }}</h5>
        <v-btn icon @click="handleCloseEmployeeNameModal">
          <i class="fas fa-times-circle color-white font-20"></i>
        </v-btn>
      </template>

      <template #default>
        <div :class="isMobile ? 'm-2' : 'w-100 d-flex flex-column'">
          <div>{{ $t('desc_for_employee_duplication') }}</div>
          <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-for="(item, index) in selectedEmployees" :key="index">
            <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ item.name }}</span>
            <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-50'}`" v-model="newEmployeeNames[index]" maxlength="100" oninput="this.value = this.value.replace(/[^0-9a-zA-Z \S]/g,'');" />
          </div>
        </div>
      </template>

      <template #modal-footer>
        <div class="w-100">
          <v-btn
            class="float-right button-small m-auto"
            @click="addNewEmployeeTask"
          >
          {{ $t('ok') }}
          </v-btn>
        </div>
      </template>
    </b-modal>
    <!-- End Employee Entering Modal -->
  </div>
</template>

<style>
  .modal-header {
    background-color: #45154D;
    color: white;
    padding: 1rem;
  }
  .modal-footer {
    padding: 1rem;
  }
  .event-box {
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    box-shadow: 4px 8px 20px rgba(34, 90, 89, 0.2) !important;
  }
</style>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { isFutureTimestamp, getCountryCityFromAddress, getDateStringFromTimestamp, getPriceFormat, getDecimalFormat, showLoading, showFunctionError } from '../../../functions';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Timestamp, getDocs, query, collection, where } from 'firebase/firestore';
import { firestore } from '../../../main';

export default {
  name: 'MyEvents',
  components: {
    DatePicker
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
    eventList() {
      return this.$store.state.eventList;
    }
  },
  watch: {
    eventList() {
      this.refreshData();
    }
  },
  data() {
    const curDate = new Date();
    return {
      tabIndex: 0,
      liveList: [],
      pastList: [],
      items: null,
      isShowEditEvent: false,
      showEventSelectingModal: false,
      showEmployeeEnteringModal: false,
      eventName: '',
      startDate: null,
      startTime: curDate,
      endDate: null,
      endTime: curDate,
      selectedEvent: null,
      targetEvent: null,
      selectedEmployees: [],
      newEmployeeNames: [],
      targetDrinkList: [],
    };
  },
  mounted() {
    if (this.myInfo) {
      if (this.myInfo.userType === this.USER_ORGANIZER) {
        if (this.$route.params.type === 'add_event') {
          this.$router.push('/add_event');
        } else {
          if (this.$route.params.type === '1') {
            setTimeout(() => {
              this.tabIndex = 1;
            }, 100);
          }
          this.refreshData();
        }
      } else {
        this.$toast.error('No permission!!');
        this.$router.push('/');
      }
    } else {
      this.$toast.info(this.$t('alert_sign_in'));
      this.$router.push('/');
    }
  },
  methods: {
    isFutureTimestamp,
    getCountryCityFromAddress,
    getDateStringFromTimestamp,
    getPriceFormat,
    getDecimalFormat,
    refreshData() {
      if (this.eventList) {
        const myId = this.$store.state.myId;
        this.liveList = this.eventList.filter(element => element.userId === myId && isFutureTimestamp(element.endAt)).sort((a, b) => (a.startAt.seconds > b.startAt.seconds) ? 1 : -1);
        this.pastList = this.eventList.filter(element => element.userId === myId && !isFutureTimestamp(element.endAt)).sort((a, b) => (a.endAt.seconds < b.endAt.seconds) ? 1 : -1);
      }
    },
    checkPermission() {
      if (this.myInfo) {
        if (this.myInfo.userType === this.USER_ORGANIZER) {
          return true;
        } else {
          this.$toast.error('No permission!!');
          this.$router.push('/');
          return false;
        }
      } else {
        this.$toast.info(this.$t('alert_sign_in'));
        this.$router.push('/');
        return false;
      }
    },
    deleteEventTask(eventInfo) {
      if (eventInfo.userId !== this.$store.state.myId) {
        this.$toast.error('This event is not yours!');
        return;
      }
      if (!isFutureTimestamp(eventInfo.endAt)) {
        this.$toast.error('This event is already expired!');
        return;
      }
      if (!confirm(this.$t('confirm_delete'))) {
        return;
      }
      const params = {
        isDelete: true,
        userId: this.$store.state.myId,
        eventId : eventInfo.eventId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEventInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.info(this.$t('alert_deleted_successfully'));
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    canImportDrinks(eventInfo) {
      let employees = this.$store.state.employeeList.filter(element => element.eventId === eventInfo.eventId);
      if (employees.length === 0 && !eventInfo.drinkTotalQuantity && isFutureTimestamp(eventInfo.startAt)) {
        return true;
      }
      return false;
    },
    handleSelectEvent(eventInfo) {
      this.targetEvent = eventInfo;
      this.showEventSelectingModal = false;
      // Get employee list from the eventInfo
      const employees = this.$store.state.employeeList.filter(element => element.eventId === eventInfo.eventId && element.role === 2 && element.userId === this.$store.state.myId);
      if (employees.length > 0) {
        this.getDrinkInfo();
        this.selectedEmployees = employees;
        this.showEmployeeEnteringModal = true;
      } else {
        this.$toast.error(this.$t('alert_not_valid_event'));
      }
    },
    handleCloseEmployeeNameModal() {
      this.showEmployeeEnteringModal = false;
      this.selectedEmployees = [];
      this.newEmployeeNames = [];
    },
    getEventListForDrinkClone() {
      return this.$store.state.eventList.filter(element => element.userId === this.$store.state.myId && element.eventId !== this.selectedEvent.eventId && element.drinkTotalQuantity > 0);
    },
    formatEventInfo() {
      this.selectedEvent = null;
      this.eventName = '';
      this.startDate = null;
      this.startTime = new Date();
      this.endDate = null;
      this.endTime = new Date();
    },
    showEventSelectingPopup(eventInfo) {
      this.selectedEvent = eventInfo;
      this.showEventSelectingModal = true;
    },
    showEventModal(eventInfo) {
      this.formatEventInfo();
      this.selectedEvent = eventInfo;
      this.isShowEditEvent = true;
    },
    getDrinkInfo() {
      this.targetDrinkList = [];
      const q = query(collection(firestore, 'drink'), where('eventId', '==', this.targetEvent.eventId), where('deletedAt', '==', null));
      getDocs(q).then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data();
          this.targetDrinkList.push(data);
        });
      });
    },
    addNewEmployeeTask() {
      if (!this.newEmployeeNames.length || this.newEmployeeNames.length !== this.selectedEmployees.length) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('employee_name')]));
        return;
      }
      const allMyEmployeeNames = [];
      this.$store.state.employeeList.forEach(element => {
        if (element.userId === this.$store.state.myId) {
          allMyEmployeeNames.push(element.name);
        }
      });
      // Check if new employee name is already exist
      this.newEmployeeNames.forEach(element => {
        const newName = element.trim();
        if (!newName) {
          this.$toast.error(this.$t('alert_required_field', [this.$t('employee_name')]));
          return;
        }
        if (allMyEmployeeNames.includes(newName)) {
          this.$toast.error(this.$t('alert_employee_name_exist', [this.$t(newName)]));
          return;
        }
        allMyEmployeeNames.push(newName);
      });
      
      // Create employee data and make function call
      const employeeNameList = this.newEmployeeNames.map(element => element.trim());
      const params = {
        userId: this.$store.state.myId,
        eventId: this.selectedEvent.eventId,
        role: 2,
        inUse: true,
        isBulk: true,
        nameList: employeeNameList,
      };

      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEmployeeInfo');
      func(JSON.stringify(params)).then(response => {
        if (response.data === this.RESULT_SUCCESS) {
          this.createDrinkTask(loader);
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    createDrinkTask(loader) {
      const drinkList = [];
      if (this.targetEvent && this.targetDrinkList.length > 0) {
        const origList = this.targetDrinkList.filter(element => element.eventId === this.targetEvent.eventId);
        if (origList && origList.length) {
          origList.forEach(drinkInfo => {
            const newDrinkInfo = {}
            newDrinkInfo.drinkId = drinkInfo.drinkId;
            newDrinkInfo.name = drinkInfo.name;
            newDrinkInfo.price = drinkInfo.price;
            newDrinkInfo.category = drinkInfo.category;
            newDrinkInfo.color = drinkInfo.color;
            newDrinkInfo.totalQuantity = drinkInfo.totalQuantity;
            if (drinkInfo.parentIDs && drinkInfo.parentIDs.length === 2) {
              newDrinkInfo.parentIDs = drinkInfo.parentIDs;
            }
            const totalMap = {};
            totalMap[this.$store.state.myId] = drinkInfo.totalQuantityMap[this.$store.state.myId] || 0;
            this.newEmployeeNames.forEach((name, index) => {
              const trimmedName = name.trim();
              totalMap[trimmedName] = drinkInfo.totalQuantityMap[this.selectedEmployees[index].name] || 0;
            });
            newDrinkInfo.totalQuantityMap = totalMap;
            drinkList.push(newDrinkInfo);
          });
          if (drinkList.length) {
            const params = {
              userId: this.$store.state.myId,
              eventId: this.selectedEvent.eventId,
              drinkList: drinkList,
            };
            const func = httpsCallable(functions, 'duplicateDrinkInfo');
            func(JSON.stringify(params)).then(response => {
              loader.hide();
              if (response.data === this.RESULT_SUCCESS) {
                this.$toast.info(this.$t('alert_added_successfully'));
                this.showEmployeeEnteringModal = false;
                this.selectedEmployees = [];
                this.newEmployeeNames = [];
                this.$router.push(`/edit_drink/${this.selectedEvent.eventId}`);
              } else {
                showFunctionError(this, response.data);
              }
            }).catch(error => {
              loader.hide();
              this.$toast.error(error.code + ', ' + error.message);
            });
          } else {
            this.$toast.error(this.$t('alert_not_valid_event'));
            loader.hide();
          }
        } else {
          this.$toast.error(this.$t('alert_not_valid_event'));
          loader.hide();
        }
      } else {
        this.$toast.error(this.$t('alert_not_valid_event'));
        loader.hide();
      }
    },
    duplicateEventInfoTask() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.eventName) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('event_name')]));
        return;
      }
      if (this.eventName.length < 4) {
        this.$toast.error(this.$t('alert_min_characters', [4, this.$t('event_name')]));
        return;
      }
      if (!this.startDate) {
        this.$toast.error(this.$t('alert_select_start_date'));
        return;
      }
      if (!this.endDate) {
        this.$toast.error(this.$t('alert_select_end_date'));
        return;
      }
      this.startDate.setHours(this.startTime.getHours());
      this.startDate.setMinutes(this.startTime.getMinutes());
      this.endDate.setHours(this.endTime.getHours());
      this.endDate.setMinutes(this.endTime.getMinutes());
      if (this.endDate.getTime() < this.startDate.getTime()) {
        this.$toast.error(this.$t('alert_end_date_must_be_greater_than_start_date'));
        return;
      }
      const newEventInfo = {
        userId: this.$store.state.myId,
        name: this.eventName,
        photoUrl: this.selectedEvent.photoUrl,
        mediaRatio: this.selectedEvent.mediaRatio,
        startAt: Timestamp.fromDate(this.startDate),
        endAt: Timestamp.fromDate(this.endDate),
        hideAddress: this.selectedEvent.hideAddress,
        address: this.selectedEvent.address,
        geoPoint: this.selectedEvent.geoPoint,
        genres: this.selectedEvent.genres,
        description: this.selectedEvent.description,
        facebookPixelId: this.selectedEvent.facebookPixelId,
        tikTokPixelId: this.selectedEvent.tikTokPixelId,
        onlyVisibleToMe: this.selectedEvent.onlyVisibleToMe,
      }

      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEventInfo');
      func(JSON.stringify(newEventInfo)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.formatEventInfo();
          this.$toast.info(this.$t('alert_added_successfully'));
          this.isShowEditEvent = false;
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>